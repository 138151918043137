import React, { useState } from "react";
import { useEffect } from "react";
import CountrySelect from "../components/countrySelect";
import axios from "axios";

export default function QuarterFinals() {
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    axios
      .get("https://bkcountdown.com/nodeapp/getQuarterCountries")
      .then((res) => {
        setCountries(res.data);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  const [groupA, setGroupA] = useState([]);
  useEffect(() => {
    axios
      .get("https://bkcountdown.com/nodeapp/getGroupA")
      .then((res) => {
        setGroupA(res.data);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  const [groupB, setGroupB] = useState([]);
  useEffect(() => {
    axios
      .get("https://bkcountdown.com/nodeapp/getGroupB")
      .then((res) => {
        setGroupB(res.data);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  const [groupC, setGroupC] = useState([]);
  useEffect(() => {
    axios
      .get("https://bkcountdown.com/nodeapp/getGroupC")
      .then((res) => {
        setGroupC(res.data);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  const [groupD, setGroupD] = useState([]);
  useEffect(() => {
    axios
      .get("https://bkcountdown.com/nodeapp/getGroupD")
      .then((res) => {
        setGroupD(res.data);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  const [groupE, setGroupE] = useState([]);
  useEffect(() => {
    axios
      .get("https://bkcountdown.com/nodeapp/getGroupE")
      .then((res) => {
        setGroupE(res.data);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  const [groupF, setGroupF] = useState([]);
  useEffect(() => {
    axios
      .get("https://bkcountdown.com/nodeapp/getGroupF")
      .then((res) => {
        setGroupF(res.data);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  const [groupG, setGroupG] = useState([]);
  useEffect(() => {
    axios
      .get("https://bkcountdown.com/nodeapp/getGroupG")
      .then((res) => {
        setGroupG(res.data);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  const [groupH, setGroupH] = useState([]);
  useEffect(() => {
    axios
      .get("https://bkcountdown.com/nodeapp/getGroupH")
      .then((res) => {
        setGroupH(res.data);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  console.log("countries", countries);

  const groupAB = groupA.concat(groupB);
  const groupCD = groupC.concat(groupD);
  const groupEF = groupE.concat(groupF);
  const groupGH = groupG.concat(groupH);

  return (
    <div class="accordion quarterFinals" id="accordionExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwo">
          <button
            class="accordion-button "
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseTwo"
            aria-expanded="true"
            aria-controls="collapseTwo"
          >
            รอบก่อนรองชนะเลิศ
          </button>
        </h2>
        <div
          id="collapseTwo"
          class="accordion-collapse collapse show"
          aria-labelledby="headingTwo"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            <div className="countryPredictionContainer">
              <div class="row">
                <div class="col-md-6">
                  <CountrySelect
                    group1="A"
                    group2="B"
                    zIndex1="99"
                    zIndex2="98"
                    zIndex3="97"
                    id1="W49"
                    id2="W50"
                    countries1={groupAB}
                    countries2={groupCD}
                  />
                  <CountrySelect
                    group1="C"
                    group2="D"
                    zIndex1="96"
                    zIndex2="95"
                    zIndex3="94"
                    id1="W53"
                    id2="W54"
                    countries1={groupEF}
                    countries2={groupGH}
                  />
                </div>
                <div class="col-md-6">
                  {" "}
                  <CountrySelect
                    group1="G"
                    group2="H"
                    zIndex1="87"
                    zIndex2="86"
                    zIndex3="85"
                    id1="W51"
                    id2="W52"
                    countries1={groupAB}
                    countries2={groupCD}
                  />
                  <CountrySelect
                    group1="E"
                    group2="F"
                    zIndex1="84"
                    zIndex2="83"
                    zIndex3="82"
                    id1="W55"
                    id2="W56"
                    countries1={groupEF}
                    countries2={groupGH}
                  />
                </div>
              </div>
              การทายที่ถูกต้องแต่ละครั้ง = 3 คะแนน สูงสุด 12 คะแนน
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
