import React from "react";
import $ from "jquery";
import "../css/countrySelect.css";
import CountrySelectBtn from "../widgets/countrySelectBtn";

export default function CountrySelectionR16(props) {
  return (
    <div className="countrySelectionContainer">
      <CountrySelectBtn
        group={props.group1}
        zIndex2={props.zIndex2}
        zIndex3={props.zIndex3}
        id={props.id1}
        countries={props.countries1}
      />
      <div className="vsLogo" style={{ zIndex: props.zIndex1 }}>
        <div className="vsText">VS</div>
      </div>{" "}
      <CountrySelectBtn
        group={props.group2}
        zIndex1={props.zIndex1}
        zIndex2={props.zIndex2}
        zIndex3={props.zIndex3}
        id={props.id2}
        countries={props.countries2}
      />
    </div>
  );
}
