import React from "react";
import "../css/footer.css";

export default function footer() {
  return (
    <div>
      <div className="footer web">
        <div className="container ">
          <div className="footerContainer">
            <div className="item-a">
              <div>
                ใบอนุญาต
                <img
                  src="./assets/gaming license/tick.svg"
                  alt=""
                  className="tick"
                />
              </div>
              <img
                src="./assets/gaming license/curacao.svg"
                alt=""
                className="curacao"
              />
              <div style={{ "padding-right": "40%" }}>
                ซึ่งถูกควบคุมและได้รับอนุญาตจากรัฐบาล Curacao
                และดำเนินงานภายใต้ใบอนุญาตหลักของผู้ให้บริการเกมออนไลน์, N.V. #
                365 / JAZ
              </div>
            </div>
            <div className="item-b">
              <div>ติดตามเราได้ที่</div>
              <span>
                <img
                  src="./assets/follow us/fb.svg"
                  alt=""
                  className="followUs"
                />
                <img
                  src="./assets/follow us/twitter.svg"
                  alt=""
                  className="followUs"
                />
                <img
                  src="./assets/follow us/instagram.svg"
                  alt=""
                  className="followUs"
                />
                <img
                  src="./assets/follow us/youtube.svg"
                  alt=""
                  className="followUs"
                />
              </span>
            </div>
            <div className="item-c">
              <div>เล่นเกมอย่างมีความรับผิดชอบ</div>
              <span>
                <img
                  src="./assets/responsible gaming/18+.svg"
                  alt=""
                  className="responsibleGaming"
                />
                <img
                  src="./assets/responsible gaming/gamcare-ov.svg"
                  alt=""
                  className="responsibleGaming"
                />
                <img
                  src="./assets/responsible gaming/begambleaware-ov.svg"
                  alt=""
                  className="responsibleGaming"
                />
              </span>
            </div>
            <div className="horizontalLine"></div>
            <div></div>
            <div></div>
            <div>ลิขสิทธิ์ © 2022 BK8™ สงวนลิขสิทธิ์</div>
          </div>
        </div>
      </div>
      <div class="footer mobile">
        <div class="gamingLicense">
          <div>
            ใบอนุญาต
            <img src="./assets/gaming license/tick.svg" alt="" class="tick" />
          </div>
          <img
            src="./assets/gaming license/curacao.svg"
            alt=""
            class="curacao"
          />
          <div>
            ซึ่งถูกควบคุมและได้รับอนุญาตจากรัฐบาล Curacao
            และดำเนินงานภายใต้ใบอนุญาตหลักของผู้ให้บริการเกมออนไลน์, N.V. # 365
            / JAZ
          </div>
        </div>
        <div class="socialMedia">
          <div class="">
            <div>ติดตามเราได้ที่</div>
            <span>
              <img src="./assets/follow us/fb.svg" alt="" class="followUs" />
              <img
                src="./assets/follow us/twitter.svg"
                alt=""
                class="followUs"
              />
              <img
                src="./assets/follow us/instagram.svg"
                alt=""
                class="followUs"
              />
              <img
                src="./assets/follow us/youtube.svg"
                alt=""
                class="followUs"
              />
            </span>
          </div>
          <div class="">
            <div>เล่นเกมอย่างมีความรับผิดชอบ</div>
            <span>
              <img
                src="./assets/responsible gaming/18+.svg"
                alt=""
                class="responsibleGaming"
              />
              <img
                src="./assets/responsible gaming/gamcare-ov.svg"
                alt=""
                class="responsibleGaming"
              />
              <img
                src="./assets/responsible gaming/begambleaware-ov.svg"
                alt=""
                class="responsibleGaming"
              />
            </span>
          </div>
        </div>
        <div class="horizontalLine"></div>
        <div>ลิขสิทธิ์ © 2022 BK8™ สงวนลิขสิทธิ์</div>
      </div>
    </div>
  );
}
