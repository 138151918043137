import axios from "axios";

export default function submitData() {
  var selectedCountry = document.getElementsByClassName("selected");
  var name = document.getElementById("usernameInput").value;
  var phone_number = document.getElementById("phone_number").value;
  var email = document.getElementById("emailInput").value;
  console.log(selectedCountry);
  var list = [];

  function getCountries() {
    for (var i = 0; i < selectedCountry.length; i++) {
      console.log(selectedCountry[i].parentElement.id);
      console.log(selectedCountry[i].children[0].children[1].innerHTML);
      var object = {
        id: selectedCountry[i].parentElement.id,
        country: selectedCountry[i].children[0].children[1].innerHTML,
      };
      list.push(object);
    }
  }

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  function postData(data) {
    axios
      .post("https://bkcountdown.com/nodeapp/addQueaterFinalsRecord", data)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function sendEmail(email, data) {
    axios
      .post(
        "https://bkcountdown.com/nodeapp/sendEmailQuarter_TH",
        {},
        {
          params: {
            email: email,
            W49: data.W49,
            W50: data.W50,
            W53: data.W53,
            W54: data.W54,
            W51: data.W51,
            W52: data.W52,
            W55: data.W55,
            W56: data.W56,
          },
        }
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function updateQuarterRecord_name(name, data) {
    axios
      .put(
        "https://bkcountdown.com/nodeapp/updateQuarterRecord_name",
        {},
        {
          params: {
            name: name,
            W49: data.W49,
            W50: data.W50,
            W53: data.W53,
            W54: data.W54,
            W51: data.W51,
            W52: data.W52,
            W55: data.W55,
            W56: data.W56,
          },
        }
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function updateQuarterRecord_phone(phone_number, data) {
    axios
      .put(
        "https://bkcountdown.com/nodeapp/updateQuarterRecord_phone",
        {},
        {
          params: {
            phone_number: phone_number,
            W49: data.W49,
            W50: data.W50,
            W53: data.W53,
            W54: data.W54,
            W51: data.W51,
            W52: data.W52,
            W55: data.W55,
            W56: data.W56,
          },
        }
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function checkRecord_name(name, email, data) {
    axios
      .get("https://bkcountdown.com/nodeapp/checkRecord_name", {
        params: { name: name },
      })
      .then((res) => {
        if (res.data.length > 0) {
          updateQuarterRecord_name(name, data);
          sendEmail(email, data);
        } else {
          postData(data);
          sendEmail(email, data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function checkRecord_phone(phone_number, email, data) {
    axios
      .get("https://bkcountdown.com/nodeapp/checkRecord_phone", {
        params: { phone_number: phone_number },
      })
      .then((res) => {
        if (res.data.length > 0) {
          updateQuarterRecord_phone(phone_number, data);
          sendEmail(email, data);
        } else {
          postData(data);
          sendEmail(email, data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //Submission with different conditions.
  if (selectedCountry.length < 8) {
    document.getElementById("modal_body").innerHTML = "กรุณาทายผลให้ครบทั้งหมด";
  } else if (name !== "" && phone_number !== "") {
    document.getElementById("modal_body").innerHTML =
      "กรอกหมายเลขโทรศัพท์หรือยูสเซอร์เนมเท่านั้น";
  } else if (name == "" && phone_number == "") {
    document.getElementById("modal_body").innerHTML =
      "กรุณากรอกหมายเลขโทรศัพท์หรือยูสเซอร์เนม";
  } else if (email == "") {
    console.log("email empty");
    document.getElementById("modal_body").innerHTML = "กรุณากรอกอีเมลของท่าน";
  } else if (isValidEmail(email) !== true) {
    console.log("email format incorrect");
    document.getElementById("modal_body").innerHTML = "อีเมลไม่ถูกค้อง";
  } else if (name !== "" && phone_number == "") {
    getCountries();
    var data = {
      name: `${name}`,
      //phone_number: "",
      email: `${email}`,
      region: "TH",
      W49: `${list[0].country}`,
      W50: `${list[1].country}`,
      W53: `${list[2].country}`,
      W54: `${list[3].country}`,
      W51: `${list[4].country}`,
      W52: `${list[5].country}`,
      W55: `${list[6].country}`,
      W56: `${list[7].country}`,
    };
    checkRecord_name(name, email, data);
    document.getElementById("modal_body").innerHTML =
      "ขอขอบคุณที่เข้าร่วมการทายผล!";
  } else if (name == "" && phone_number !== "") {
    console.log("Submit with phone number!");
    getCountries();
    var data = {
      //name: "",
      phone_number: `${phone_number}`,
      email: `${email}`,
      region: "TH",
      W49: `${list[0].country}`,
      W50: `${list[1].country}`,
      W53: `${list[2].country}`,
      W54: `${list[3].country}`,
      W51: `${list[4].country}`,
      W52: `${list[5].country}`,
      W55: `${list[6].country}`,
      W56: `${list[7].country}`,
    };
    checkRecord_phone(phone_number, email, data);
    document.getElementById("modal_body").innerHTML =
      "ขอขอบคุณที่เข้าร่วมการทายผล!";
    window.open("https://bit.ly/3T99IP1");
  }
}
