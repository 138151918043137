import React, { useEffect, useState, useLayoutEffect } from "react";
import "../css/countrySelectBtn.css";
import $ from "jquery";
import Data from "../country.json";

export default function CountrySelectBtn(props) {
  function expandOption(data) {
    if ($("#" + data).css("display") == "none") {
      $("#" + data).show();
    } else {
      $("#" + data).hide();
    }
  }

  function selectCountry() {
    var button = document
      .getElementById(props.id)
      .getElementsByClassName("selectedCountryBtn");

    var addSelectClass = function () {
      removeSelectClass();
      this.classList.add("selected");
      //Remove event listener
      for (var i = 0; i < button.length; i++) {
        button[i].removeEventListener("click", addSelectClass);
      }

      $("#" + props.id + "-C" + " " + ".country").html(
        $("#" + props.id + " " + ".selected").html()
      );

      $("#" + props.id + "-C" + " " + ".country").css(
        "background-color",
        "#fff"
      );

      expandOption(props.id);
    };
    var removeSelectClass = function () {
      for (var i = 0; i < button.length; i++) {
        button[i].classList.remove("selected");
      }
    };
    for (var i = 0; i < button.length; i++) {
      button[i].addEventListener("click", addSelectClass);
    }
  }

  window.setTimeout(() => {
    var button = document
      .getElementById(props.id)
      .getElementsByClassName("selectedCountryBtn");

    var addSelectClass = function () {
      removeSelectClass();
      $("#" + props.id).hide();
      this.classList.add("selected");
      //Remove event listener
      for (var i = 0; i < button.length; i++) {
        button[i].removeEventListener("click", addSelectClass);
      }

      $("#" + props.id + "-C" + " " + ".country").html(
        $("#" + props.id + " " + ".selected").html()
      );

      $("#" + props.id + "-C" + " " + ".country").css(
        "background-color",
        "#fff"
      );

      //expandOption(props.id);
    };
    var removeSelectClass = function () {
      for (var i = 0; i < button.length; i++) {
        button[i].classList.remove("selected");
      }
    };
    for (var i = 0; i < button.length; i++) {
      button[i].addEventListener("click", addSelectClass);
    }
  }, 500);

  return (
    <div className="countrySelectionSubContainer" id={props.id + "-C"}>
      <button
        type="button"
        class="country"
        onClick={() => {
          expandOption(props.id);
        }}
        //onMouseOver={selectCountry}
        style={{ zIndex: props.zIndex2 }}
      >
        <span>{props.id}</span>
        <img src="./assets/add-01.svg" alt="" className="plus" />
      </button>
      <div class="content" id={props.id} style={{ zIndex: props.zIndex3 }}>
        {props.countries.map(({ file_path, country }, index) => (
          <div className="selectedCountryBtn">
            <button className="countryBtn" onClick={selectCountry}>
              <img src={file_path} alt={index} className="flag" />
              <span className="countryText">{country}</span>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}
