import React from "react";
import CountrySelect from "../components/countrySelect";
import axios from "axios";
import { useEffect, useState } from "react";

export default function SemiFinals() {
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    axios
      .get("https://bkcountdown.com/nodeapp/getSemiCountries")
      .then((res) => {
        setCountries(res.data);
        console.log("semi finals", res.data);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  let W57_countries = countries.filter(
    (country) => country.match_code == "W57"
  );
  let W58_countries = countries.filter(
    (country) => country.match_code == "W58"
  );
  let W59_countries = countries.filter(
    (country) => country.match_code == "W59"
  );
  let W60_countries = countries.filter(
    (country) => country.match_code == "W60"
  );

  return (
    <div class="accordion SemiFinals" id="accordionExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingThree">
          <button
            class="accordion-button "
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseThree"
            aria-expanded="true"
            aria-controls="collapseThree"
          >
            รอบรองชนะเลิศ
          </button>
        </h2>
        <div
          id="collapseThree"
          class="accordion-collapse collapse show"
          aria-labelledby="headingThree"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            <div className="countryPredictionContainer">
              <div class="row">
                <div class="col">
                  <CountrySelect
                    group1="A"
                    group2="B"
                    zIndex1="99"
                    zIndex2="98"
                    zIndex3="97"
                    id1="W57"
                    id2="W58"
                    countries1={W57_countries}
                    countries2={W58_countries}
                  />
                </div>
                <div class="col">
                  <CountrySelect
                    group1="A"
                    group2="B"
                    zIndex1="99"
                    zIndex2="98"
                    zIndex3="97"
                    id1="W59"
                    id2="W60"
                    countries1={W59_countries}
                    countries2={W60_countries}
                  />
                </div>
              </div>
              การทายที่ถูกต้องแต่ละครั้ง = 4 คะแนน สูงสุด 8 คะแนน
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
