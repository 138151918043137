import React from "react";
import $ from "jquery";
import "../css/tnc.css";

export default function tnc() {
  function triggerHowToApply() {
    $("#howToApplyContainer").show();
    $("#termConditionContainer").hide();
    $("#howToApplyBtn").css("background-color", "#8a1538");
    $("#termConditionBtn").css("background-color", "transparent");
  }

  function triggerTermsCondition() {
    $("#howToApplyContainer").hide();
    $("#termConditionContainer").show();
    $("#howToApplyBtn").css("background-color", "transparent");
    $("#termConditionBtn").css("background-color", "#8a1538");
  }
  return (
    <div className="tnc">
      <div className="container">
        <button
          className="howToApplyBtn"
          id="howToApplyBtn"
          onClick={triggerHowToApply}
        >
          รางวัล
        </button>
        <button
          className="termConditionBtn"
          id="termConditionBtn"
          onClick={triggerTermsCondition}
        >
          เงื่อนไขและข้อกำหนด
        </button>
        <div className="howToApplyContainer" id="howToApplyContainer">
          รางวัล:
          <ol>
            <li>รางวัลรวมมีมูลค่ากว่า 1,000,000 บาท</li>
            <li>
              ผู้ที่ได้คะแนนสูงมีสิทธิ์ลุ้นเป็นผู้โชคดี
              เยือนประเทศผู้ชนะฟุตบอลโลก 2022 กับ BK8
            </li>
            <li>
              รางวัลจะเป็นรูปแบบโบนัสฟรี ในกรณีที่มีสมาชิกได้ผลคะแนนเท่ากัน
              สมาชิกจะรับรางวัลเป็นส่วนแบ่งเงินรางวัล
            </li>
            <li>
              สมาชิกผู้ชนะในโปรโมชั่นนี้จะต้องตกลงและมีส่วนร่วมในกิจกรรมทางโซเชียลมีเดียที่เกิดจากโปรโมชั่นนี้
            </li>
            <li>
              รางวัลที่ได้รับในรูปแบบโบนัสจะต้องทำยอดเทิร์น 1 เท่าก่อนถอนเงิน
            </li>
            <li>รางวัลอาจมีการเปลี่ยนแปลงได้ตามอัตราแลกเปลี่ยนสกุลเงิน</li>
          </ol>
        </div>
        <div className="termConditionContainer" id="termConditionContainer">
          เงื่อนไขและข้อกำหนด:
          <ol>
            <li>
              โปรโมชั่นนี้สงวนสิทธิ์ให้แก่สมาชิกที่มีบัญชีเงินจริงกับลูกค้า BK8
              ทุกท่าน
            </li>
            <li>
              สมาชิกจะต้องยืนยันการทายผลก่อนการแข่งขันฟุตบอลโลกเริ่มต้นขึ้น
              หรือภายในวันที่ 20 พ.ย.
            </li>
            <li>
              สมาชิกจะต้องทายผลได้ถูกต้องตั้งแต่รอบแบ่งกลุ่มจนถึงรอบชิงชนะเลิศ
              โดยสมาชิกที่ทายผลถูกต้องทุกแมตช์จะได้รับแจ็คพอตใหญ่
            </li>
            <li>
              สมาชิก 1 ท่านสามารถทายผลได้เพียง 1 ครั้งเท่านั้น
              ในกรณีที่มีการทายผลหลายครั้งจะพิจารณาจากการทายผลครั้งสุดท้ายเท่านั้น
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}
