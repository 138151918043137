import axios from "axios";

export default function SubmitData() {
  var selectedCountry = document.getElementsByClassName("selected");
  var name = document.getElementById("usernameInput").value;
  var phone_number = document.getElementById("phone_number").value;
  var email = document.getElementById("emailInput").value;
  var list = [];

  function getCountries() {
    for (var i = 0; i < selectedCountry.length; i++) {
      var object = {
        id: selectedCountry[i].parentElement.id,
        country: selectedCountry[i].children[0].children[1].innerHTML,
      };
      list.push(object);
    }
  }

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  function postData(data) {
    axios
      .post("https://bkcountdown.com/nodeapp/addR16Record", data)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function sendEmail(email, data) {
    axios
      .post(
        "https://bkcountdown.com/nodeapp/sendEmailR16_TH",
        {},
        {
          params: {
            email: email,
            A1: data.A1,
            B1: data.B1,
            C1: data.C1,
            D1: data.D1,
            E1: data.E1,
            F1: data.F1,
            G1: data.G1,
            H1: data.H1,
            A2: data.A2,
            B2: data.B2,
            C2: data.C2,
            D2: data.D2,
            E2: data.E2,
            F2: data.F2,
            G2: data.G2,
            H2: data.H2,
          },
        }
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function updateR16Record_name(name, data) {
    axios
      .put(
        "https://bkcountdown.com/nodeapp/updateR16Record_name",
        {},
        {
          params: {
            name: name,
            A1: data.A1,
            B1: data.B1,
            C1: data.C1,
            D1: data.D1,
            E1: data.E1,
            F1: data.F1,
            G1: data.G1,
            H1: data.H1,
            A2: data.A2,
            B2: data.B2,
            C2: data.C2,
            D2: data.D2,
            E2: data.E2,
            F2: data.F2,
            G2: data.G2,
            H2: data.H2,
          },
        }
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function updateR16Record_phone(phone_number, data) {
    axios
      .put(
        "https://bkcountdown.com/nodeapp/updateR16Record_phone",
        {},
        {
          params: {
            phone_number: phone_number,
            A1: data.A1,
            B1: data.B1,
            C1: data.C1,
            D1: data.D1,
            E1: data.E1,
            F1: data.F1,
            G1: data.G1,
            H1: data.H1,
            A2: data.A2,
            B2: data.B2,
            C2: data.C2,
            D2: data.D2,
            E2: data.E2,
            F2: data.F2,
            G2: data.G2,
            H2: data.H2,
          },
        }
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function checkRecord_name(name, email, data) {
    axios
      .get("https://bkcountdown.com/nodeapp/checkRecord_name", {
        params: { name: name },
      })
      .then((res) => {
        if (res.data.length > 0) {
          updateR16Record_name(name, data);
          sendEmail(email, data);
        } else {
          postData(data);
          sendEmail(email, data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function checkRecord_phone(phone_number, email, data) {
    axios
      .get("https://bkcountdown.com/nodeapp/checkRecord_phone", {
        params: { phone_number: phone_number },
      })
      .then((res) => {
        if (res.data.length > 0) {
          updateR16Record_phone(phone_number, data);
          sendEmail(email, data);
        } else {
          postData(data);
          sendEmail(email, data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //Submission with different conditions.
  if (selectedCountry.length < 16) {
    document.getElementById("modal_body").innerHTML = "กรุณาทายผลให้ครบทั้งหมด";
  } else if (name !== "" && phone_number !== "") {
    document.getElementById("modal_body").innerHTML =
      "กรอกหมายเลขโทรศัพท์หรือยูสเซอร์เนมเท่านั้น";
  } else if (name == "" && phone_number == "") {
    document.getElementById("modal_body").innerHTML =
      "กรุณากรอกหมายเลขโทรศัพท์หรือยูสเซอร์เนม ";
  } else if (email == "") {
    console.log("email empty");
    document.getElementById("modal_body").innerHTML = "กรุณากรอกอีเมลของท่าน";
  } else if (isValidEmail(email) !== true) {
    console.log("email format incorrect");
    document.getElementById("modal_body").innerHTML = "อีเมลไม่ถูกค้อง";
  } else if (name !== "" && phone_number == "") {
    console.log("Submit with name!");
    console.log(isValidEmail(email));
    getCountries();
    var data = {
      name: `${name}`,
      //phone_number: `${phone_number}`,
      email: `${email}`,
      region: "TH",
      A1: `${list[0].country}`,
      B1: `${list[1].country}`,
      C1: `${list[2].country}`,
      D1: `${list[3].country}`,
      E1: `${list[4].country}`,
      F1: `${list[5].country}`,
      G1: `${list[6].country}`,
      H1: `${list[7].country}`,
      A2: `${list[8].country}`,
      B2: `${list[9].country}`,
      C2: `${list[10].country}`,
      D2: `${list[11].country}`,
      E2: `${list[12].country}`,
      F2: `${list[13].country}`,
      G2: `${list[14].country}`,
      H2: `${list[15].country}`,
    };

    //Check if name existed, then post or update data.
    checkRecord_name(name, email, data);

    //Update modal text
    document.getElementById("modal_body").innerHTML =
      "ขอขอบคุณที่เข้าร่วมการทายผล!";
  } else if (name == "" && phone_number !== "") {
    getCountries();
    var data = {
      //name: `${name}`,
      phone_number: `${phone_number}`,
      email: `${email}`,
      region: "TH",
      A1: `${list[0].country}`,
      B1: `${list[1].country}`,
      C1: `${list[2].country}`,
      D1: `${list[3].country}`,
      E1: `${list[4].country}`,
      F1: `${list[5].country}`,
      G1: `${list[6].country}`,
      H1: `${list[7].country}`,
      A2: `${list[8].country}`,
      B2: `${list[9].country}`,
      C2: `${list[10].country}`,
      D2: `${list[11].country}`,
      E2: `${list[12].country}`,
      F2: `${list[13].country}`,
      G2: `${list[14].country}`,
      H2: `${list[15].country}`,
    };

    checkRecord_phone(phone_number, email, data);
    //postData(data);
    document.getElementById("modal_body").innerHTML =
      "ขอขอบคุณที่เข้าร่วมการทายผล!";
    window.open("https://bit.ly/3T99IP1");
  }
}
